import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Rfq = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_cal25fw",
        "template_uph7tas",
        form.current,
        "CtdZOzPDsI1EeNx0V"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          toast.success("Message sent successfully!");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          toast.error("Failed to send message");
        }
      );
  };

  return (
    <div>
      <div className="border rounded-lg p-4 flex flex-col justify-center items-center h-full">
        <div>
          <strong>
            <h1 className="font-sans pb-5 lg:text-3xl text-purple-900 text-center">
              Request for Quotation
            </h1>
          </strong>
          <div className="max-w-md pt-2 pb-4">
            <p className="text-gray-500 text-center font-sans lg:text-sm">
              Streamline your procurement process with our eco-friendly
              solutions. Submit your chemical/equipment effortlessly through our
              user-friendly RFQ form, ensuring a sustainable future with every
              purchase decision. Join us in our mission to promote environmental
              consciousness while meeting your business needs effectively.
            </p>
          </div>
        </div>
        <StyledContactForm>
          <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Contact No</label>
            <input
              type="tel"
              className="contact_number"
              name="user_contact"
              placeholder="03XX-XXXXXXX"
              pattern="[0-9]+\d{6}"
              required
            />{" "}
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
          </form>
        </StyledContactForm>
      </div>
    </div>
  );
};

export default Rfq;

// Styles
const StyledContactForm = styled.div`
  width: 400px;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
  }
`;
