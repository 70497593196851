import React from "react";
import Logo from "../../assets/media/images/Logo.png";

import { Navbar } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  return (
    <>
      <div className="border md:hidden">
        <Navbar fluid rounded className="flex flex-row flex-nowrap	">
          <Navbar.Brand as={Link} href="/">
            <img src={Logo} className="h-20 logo_size" alt="Flowbite Logo" />
          </Navbar.Brand>
          <Navbar.Toggle className="nav_icon" />
          <Navbar.Collapse className="z-50 bg-white right-0 top-14 absolute">
            <Navbar.Link href="/" active>
              Home
            </Navbar.Link>
            <Navbar.Link href="/about">About</Navbar.Link>
            <Navbar.Link href="/services">Services</Navbar.Link>
            <Navbar.Link href="/rfq">Get your Quotation</Navbar.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="border hidden md:flex ">
        <div className="flex flex-col max-w-screen-xl items-center justify-between mx-auto p-4">
          <div>
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src={Logo} className="h-20" alt="Flowbite Logo" />
            </Link>
          </div>
          <div className="items-center max-w-sm justify-between pt-5">
            <div className="">
              <ul className="p-4 flex align-center justify-center flex-row md:p-0 mt-4 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 rounded ${
                      location.pathname === "/"
                        ? "bg-blue-700 text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 rounded ${
                      location.pathname === "/about"
                        ? "bg-blue-700 text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services"
                    className={`block py-2 px-3 rounded ${
                      location.pathname === "/services"
                        ? "bg-blue-700 text-white"
                        : "text-gray-900 hover:bg-gray-100"
                    }`}
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/rfq"
                    className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 truncate"
                  >
                    Get your Quotation
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
