import React from "react";

const ServiceCard = (props) => {
  return (
    <div className="pr-5 pl-5">
      <div
        style={{ maxWidth: "25rem" }}
        className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
      >
        <a href="/#">
          <img
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
            className="rounded-t-lg"
            src={props.service.src}
            alt=""
          />
        </a>
        <div className="p-5">
          <a href="/#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {props.service.title}
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {props.service.desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
