import React from "react";
import { Footer as foot } from "flowbite-react";
import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import {} from "react-icons/fa";
import Logo from "../../assets/media/images/Logo.png";

const Footer = () => {
  return (
    <foot container>
      <div className="border-t pl-20 pr-20 pt-10">
        <div className="w-full">
          <div className="grid w-full justify-between lg:items-center sm:flex sm:justify-between md:flex md:grid-cols-1">
            <div>
              <foot.Brand src={Logo} alt="Flowbite Logo" />
            </div>
            <div className="grid gap-8 sm:mt-4 sm:gap-6">
              <div className="pt-4">
                <foot.Title clas title="Contact Us" />
                <foot.LinkGroup col>
                  <foot.Link
                    className="text-blue-400"
                    href="gogreentraders1@gmail.com"
                  >
                    gogreentraders1@gmail.com
                  </foot.Link>
                  <foot.Link href="#">
                    <foot.Icon href="#" icon={BsWhatsapp} />
                    <p className="pt-2">03421466065</p>
                  </foot.Link>
                  <foot.Link href="#">
                    48-A Punjab University Employees Housing Society Town-1,
                    Abdul Sattar Edhi Road, Lahore
                  </foot.Link>
                </foot.LinkGroup>
              </div>
            </div>
          </div>
          <foot.Divider />
          <div className="w-full sm:flex sm:items-center sm:justify-between">
            <foot.Copyright href="#" by="Go-Green Traders" year={2024} />
            <div className="mt-4 mb-4 flex space-x-6 sm:mt-0 sm:justify-center">
              <foot.Icon href="#" icon={BsFacebook} />
              <foot.Icon href="#" icon={BsInstagram} />
              <foot.Icon href="#" icon={BsTwitter} />
            </div>
          </div>
        </div>
      </div>
    </foot>
  );
};

export default Footer;
