import React from "react";
import { Card, Carousel } from "flowbite-react";
import ProductSale from "../../assets/media/images/ProductSale.jpeg";
import CustomOrders from "../../assets/media/images/CustomOrders.jpeg";
import TechnicalSupport from "../../assets/media/images/TechnicalSupport.jpeg";
import RepairsServices from "../../assets/media/images/RepairsServices.jpeg";
import SafetyConsultations from "../../assets/media/images/SafetyConsultations.jpeg";
import DeliveryServices from "../../assets/media/images/DeliveryServices.jpeg";
import ProductInformation from "../../assets/media/images/ProductInformation.jpeg";
import BulkPurchase from "../../assets/media/images/BulkPurchase.jpeg";
import OnlinePlatform from "../../assets/media/images/OnlinePlatform.jpeg";
import GlobalImports from "../../assets/media/images/GlobalImports.jpeg";

const Home = () => {
  return (
    <>
      <div className="lg:pl-10 lg:pr-10">
        <div className="sm:pl-14 sm:pr-14">
          <div className="h-60 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel>
              <img src={ProductSale} alt="..." />
              <img src={CustomOrders} alt="..." />
              <img src={TechnicalSupport} alt="..." />
              <img src={RepairsServices} alt="..." />
              <img src={SafetyConsultations} alt="..." />
              <img src={DeliveryServices} alt="..." />
              <img src={ProductInformation} alt="..." />
              <img src={BulkPurchase} alt="..." />
              <img src={OnlinePlatform} alt="..." />
              <img src={GlobalImports} alt="..." />
            </Carousel>
          </div>
          <div>
            <h3 className="font-sans pt-10 text-2xl lg:text-5xl text-center text-purple-900">
              <strong>Welcome to Go-Green Traders</strong>
            </h3>
            <p className="text-center font-sans pt-10 lg:text-lg">
              Welcome to Go-Green Traders, a pioneering force in the realm of
              chemical and industrial equipment, where innovation and precision
              converge to shape the future of industrial processes. With a rich
              legacy of excellence, we stand as a beacon of reliability,
              committed to delivering state-of-the-art solutions that redefine
              industry standards.
            </p>
          </div>
          <div>
            <strong>
              <h1 className="p-4 font-sans pt-10 lg:text-3xl text-purple-900 text-center">
                Main Objectives
              </h1>
            </strong>
          </div>

          <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 lg:pl-14 lg:pr-14">
            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Providing High-Quality Products
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                Ensuring that the equipment meets industry standards and
                customer expectations for reliability and performance.
              </p>
            </Card>
            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Customer Satisfaction
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                Building strong relationships with customers by understanding
                their needs, offering excellent service, and providing reliable
                support.
              </p>
            </Card>
            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Market Expansion
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                Identifying new markets and opportunities to grow the customer
                base and increase sales.
              </p>
            </Card>

            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Innovation
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                EContinuously improving products and services to stay
                competitive and meet evolving industry demands.
              </p>
            </Card>
            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Operational Efficiency
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                Streamlining internal processes to reduce costs, improve
                productivity, and enhance overall efficiency.
              </p>
            </Card>
            <Card href="#" className="max-w-sm">
              <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Brand Reputation
              </h5>
              <p className="font-normal text-center text-gray-700 dark:text-gray-400">
                Establishing a positive reputation in the industry through
                consistent delivery of quality products and excellent customer
                service.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
