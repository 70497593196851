import { Card } from "flowbite-react";
import React from "react";

const AboutUs = () => {
  return (
    <div className="lg:pl-10 lg:pr-10">
      <div className="sm:pl-14 sm:pr-14">
        <div>
          <h3 className="font-sans pt-10 text-2xl lg:text-5xl text-center text-purple-900">
            <strong>Welcome to Go-Green Traders</strong>
          </h3>
          <p className="font-sans pt-10 lg:text-lg">
            Welcome to Go-Green Traders, a pioneering force in the realm of
            chemical and industrial equipment, where innovation and precision
            converge to shape the future of industrial processes. With a rich
            legacy of excellence, we stand as a beacon of reliability, committed
            to delivering state-of-the-art solutions that redefine industry
            standards.
          </p>
          <p className="font-sans pt-3 lg:text-lg">
            At Go-Green Traders, we understand the dynamic challenges that
            industries face, and our mission is to provide cutting-edge
            equipment that not only meets but exceeds expectations. From
            advanced chemical processing units to robust industrial machinery,
            our product portfolio is a testament to our unwavering commitment to
            efficiency, safety, and performance.
          </p>
          <p className="font-sans pt-3 lg:text-lg">
            What sets us apart is our relentless pursuit of innovation. Our team
            of experts leverages the latest technologies to develop solutions
            that address the evolving needs of our clients. We pride ourselves
            on being more than just a provider; we are your strategic partner in
            success, working collaboratively to optimize processes and maximize
            output.
          </p>
          <p className="font-sans pt-3 lg:text-lg">
            Quality is the cornerstone of our philosophy. Every product bearing
            the Go-Green Traders insignia undergoes rigorous testing and adheres
            to the highest industry standards. This commitment to quality
            ensures that our clients receive equipment that not only meets
            regulatory requirements but also stands the test of time.
          </p>
          <p className="font-sans pt-3 lg:text-lg">
            Embark on a journey with Go-Green Traders, where reliability,
            expertise, and forward-thinking solutions converge to propel your
            operations to new heights. Experience the difference of working with
            a company that doesn't just meet expectations but sets the benchmark
            for excellence in the chemical and industrial equipment landscape.
          </p>
        </div>
        <div>
          <strong>
            <h1 className="font-sans pt-10 lg:text-3xl text-purple-900 text-center">
              Main Objectives
            </h1>
          </strong>
        </div>

        <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-4 lg:pl-14 lg:pr-14">
          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Providing High-Quality Products
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              Ensuring that the equipment meets industry standards and customer
              expectations for reliability and performance.
            </p>
          </Card>
          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Customer Satisfaction
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              Building strong relationships with customers by understanding
              their needs, offering excellent service, and providing reliable
              support.
            </p>
          </Card>
          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Market Expansion
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              Identifying new markets and opportunities to grow the customer
              base and increase sales.
            </p>
          </Card>

          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Innovation
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              EContinuously improving products and services to stay competitive
              and meet evolving industry demands.
            </p>
          </Card>
          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Operational Efficiency
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              Streamlining internal processes to reduce costs, improve
              productivity, and enhance overall efficiency.
            </p>
          </Card>
          <Card href="#" className="max-w-sm">
            <h5 className="text-center text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Brand Reputation
            </h5>
            <p className="font-normal text-center text-gray-700 dark:text-gray-400">
              Establishing a positive reputation in the industry through
              consistent delivery of quality products and excellent customer
              service.
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
