import React from "react";
import ProductSale from "../../assets/media/images/ProductSale.jpeg";
import CustomOrders from "../../assets/media/images/CustomOrders.jpeg";
import TechnicalSupport from "../../assets/media/images/TechnicalSupport.jpeg";
import RepairsServices from "../../assets/media/images/RepairsServices.jpeg";
import SafetyConsultations from "../../assets/media/images/SafetyConsultations.jpeg";
import DeliveryServices from "../../assets/media/images/DeliveryServices.jpeg";
import ProductInformation from "../../assets/media/images/ProductInformation.jpeg";
import BulkPurchase from "../../assets/media/images/BulkPurchase.jpeg";
import OnlinePlatform from "../../assets/media/images/OnlinePlatform.jpeg";
import GlobalImports from "../../assets/media/images/GlobalImports.jpeg";
import ServiceCard from "./serviceCard/ServiceCard";
import TrainingPrograms from "../../assets/media/images/TrainingPrograms.png";
const Service = () => {
  const services = [
    {
      title: "Product Sales",
      src: ProductSale,
      desc: "Providing a variety of chemicals (industrial and fine chemicals), laboratory and industrial equipment.",
    },
    {
      title: "Custom Orders",
      src: CustomOrders,
      desc: "Offering customized glassware, chemical/technical solutions based on specific customer needs.",
    },
    {
      title: "Technical Support",
      src: TechnicalSupport,
      desc: "Providing expert opinion on the selection and use of chemicals and equipment.",
    },
    {
      title: "Repair Services",
      src: RepairsServices,
      desc: "Offering repairs and maintanence of laboratory and industrial equipments.",
    },
    {
      title: "Training Programs",
      src: TrainingPrograms,
      desc: "Conducting training sessions on proper handling and use of chemicals and equipment.",
    },
    {
      title: "Safety Consultation",
      src: SafetyConsultations,
      desc: "Providing guidance on safety measures and protocols for handling chemicals and equipment.",
    },
    {
      title: "Delivery Services",
      src: DeliveryServices,
      desc: "Efficient delivery of products to customers, ensuring timely and safe arrival.",
    },
    {
      title: "Product Information",
      src: ProductInformation,
      desc: "Supplying detailed information about the chemicals and equipment they offer.",
    },
    {
      title: "Bulk Purchases",
      src: BulkPurchase,
      desc: "Catering to bulk orders for chemicals, glassware, industrial and laboratory equipments.",
    },
    {
      title: "Online Platform",
      src: OnlinePlatform,
      desc: "Establishing an online platform for easy ordering and information access.",
    },
    {
      title: "Global Imports",
      src: GlobalImports,
      desc: "We also offer Global import of the chemicals and industrial equipments needed for the daily use of our industries.",
    },
  ];
  return (
    <div className="pt-5 grid lg:grid-cols-3 sm:grid-cols-1 gap-4">
      {services.map((service, index) => (
        <div key={index} className="flex justify-center">
          <ServiceCard key={index} service={service} />
        </div>
      ))}
    </div>
  );
};

export default Service;
