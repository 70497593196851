import "./App.css";
import AboutUs from "./Components/aboutUs/AboutUs";
import Home from "./Components/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Components/layout/Layout";
import Service from "./Components/service/Service";
import Rfq from "./Components/rfq/Rfq";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // const location = useLocation();
  return (
    <>
      <Router>
        <Layout>
        <ToastContainer />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Service />} />
            <Route path="/rfq" element={<Rfq />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
